import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Pagination from "../components/common/Pagination"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const NewsIndex = props => {
  const { data, pageContext } = props
  return (
    <Layout>
      <h1>
        All <span className="purple-underline">News</span>
      </h1>
      <div className="content-wrapper position-relative" style={{ zIndex: 2 }}>
        <div className="pe-5">
          {data.drupal.nodeQuery.entities.map((entity, index) => {
            const created = new Date(entity?.created * 1000).toLocaleString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )
            const thumbnail = getImage(entity?.thumbnail || {})
            const entityUrl = entity?.entityUrl?.path
            return (
              <div className="row py-5" key={index}>
                <div className="col">
                  <div>
                    {thumbnail && (
                      <div className="py-4">
                        <GatsbyImage
                          alt={entity?.entityLabel || ""}
                          image={thumbnail}
                          imgClassName="preview"
                          loading="eager"
                          className="rounded-4"
                        />
                      </div>
                    )}
                  </div>
                  <div>{created}</div>
                  <h3 className="pt-4">{entity?.entityLabel}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: entity?.body?.summary }}
                  />
                  <div className="py-2">
                    <Link to={entityUrl} className="read-more">
                      Read More >>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="py-4">
          <Pagination pageContext={{ ...pageContext, pagePrefix: "news" }} />
        </div>
      </div>
      <div className="py-4" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/" className="white">
          Go back to the homepage
        </Link>
      </div>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    drupal {
      nodeQuery(
        offset: $skip
        limit: $limit
        filter: {
          conditions: [
            { field: "status", value: "1", operator: EQUAL }
            { field: "type", value: "article", operator: EQUAL }
          ]
        }
        sort: { field: "created", direction: DESC }
      ) {
        entities {
          entityId
          entityLabel
          entityCreated
          entityUrl {
            path
          }
          ... on Default_NodeArticle {
            image: fieldImage {
              url
              id: targetId
            }
            body {
              value: processed
              summary: summaryProcessed
            }
            thumbnail: gatsbyImageRectThumbnail {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
            created
          }
        }
      }
    }
  }
`

export default NewsIndex
